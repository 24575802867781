import { CircularProgress } from "@mui/material";
import classes from "./LoadingContainer.module.css";

const LoadingContainer = () => {
  return (
    <div className={classes.container}>
      <CircularProgress
        size="2rem"
        style={{ color: "#664efe" }}
        thickness={4.6}
      />
    </div>
  );
};

export default LoadingContainer;
