import { useState, useContext, useEffect } from "react";
import SchoolPages from "../Components/SchoolPages/SchoolPages";
import { AppContext } from "../Context/AppContext";
import { useParams } from "react-router-dom";
import { SchoolsContext } from "../Context/SchoolsContext";
import LoadingContainer from "../Components/LoadingContainer/LoadingContainer";
import Layout from "../Components/Layout/Layout";
import ErrorPage from "../Containers/404/404";

const SchoolOfBusiness = () => {
  // Router
  const { schoolId } = useParams();

  // Context
  const { coursesRef, schoolCourseRef, benefits, tutorsRef, overviewRef } =
    useContext(AppContext);
  const { schoolsDetails, fetchSchoolById } = useContext(SchoolsContext);

  // States
  const [navItems, setNavItems] = useState([
    {
      title: "Overview",
      isActive: true,
      activeComponent: null,
      onClick: () => {
        overviewRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Courses",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        schoolCourseRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Benefits",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        benefits.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "School tutors",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        tutorsRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    // {
    //   title: "FAQ",
    //   isActive: false,
    //   activeComponent: null,
    //   onClick: () => {
    //   },
    // },
  ]);

  // Effects
  useEffect(() => {
    fetchSchoolById(schoolId as string);

    // eslint-disable-next-line
  }, [schoolId]);

  if (!schoolsDetails?.data && !schoolsDetails.isLoading) {
    return (
      <ErrorPage
        title="Oops! This school has no courses for now"
        description={`It seems like this school doesn't have any courses available at the moment. But don't worry, our team is working hard to bring you exciting learning opportunities soon!\nIn the meantime, you can explore other schools and courses available on our platform. Feel free to reach out to our support team if you have any questions or need assistance`}
      />
    );
  }

  return (
    <>
      {schoolsDetails?.isLoading ? (
        <Layout>
          <LoadingContainer />
        </Layout>
      ) : (
        <SchoolPages
          navItems={navItems}
          setNavItems={setNavItems}
          heroImage={schoolsDetails?.data?.image}
          header={schoolsDetails?.data?.name}
          paragraph="HELP BUSINESSES GROW"
          subHeading="Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills."
          coursesRef={coursesRef}
        />
      )}
    </>
  );
};

export default SchoolOfBusiness;
