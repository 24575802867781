import classes from "./SpomsorAStudentOptions.module.css";
import sponsorshipOption1 from "../../Assets/Images/sponsorshipOption1.svg";
import sponsorshipOption2 from "../../Assets/Images/sponsorshipOption2.svg";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

const SpomsorAStudentOptions = () => {
  // Navigate
  const navigate = useNavigate();

  // Utils
  const sponsorOptions = [
    {
      image: sponsorshipOption1,
      header: "Sponsor student fees",
      paragraph:
        "Empower tech minds with your sponsorship. Help cover students' fees to acquire skills and open doors to endless possibilities in the tech world. Make a difference in their tech journey today",
      buttontext: "Sponsor a student",
      onClick: () => {
        scrollToTheTop();
        navigate("/sponsor-a-student/sponsorship-application-form");
      },
    },
    {
      image: sponsorshipOption2,
      header: "Donate a laptop",
      paragraph:
        "Support tech lovers with a laptop donation. Your contribution provides essential tools for their educational journey, empowering them to thrive in the digital world and create a brighter future.",
      buttontext: "Donate now",
      onClick: () => {
        scrollToTheTop();
        navigate("/sponsor-a-student/laptop-donation-form");
      },
    },
  ];

  return (
    <section className={classes.container}>
      {sponsorOptions.map((data, i) => {
        return (
          <div key={i} className={classes.option}>
            <img src={data.image} alt={data.header} />
            <div>
              <SectionsHeader
                description={data.paragraph}
                header={data.header}
              />
              <Button onClick={data.onClick}>{data.buttontext}</Button>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default SpomsorAStudentOptions;
