import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./WhyIseCollaborateWithUs.module.css";
import whyIseCOllaborateWithUs from "../../Assets/Images/whyIseCOllaborateWithUs.svg";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

const WhyIseCollaborateWithUs = () => {
  // Navigate
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div className={classes.textSection}>
        <SectionsHeader
          header="Collaborate with us"
          paragraph="SHAPE TALENTS"
          description="Join our mission to support tech growth through donations, scholarships, and your valuable contributions. Together, let's shape the next generation of tech leaders. "
        />
        <Button
          onClick={() => {
            navigate("/sponsor-a-student");
            scrollToTheTop();
          }}
        >
          Become a sponsor
        </Button>
      </div>
      <div className={classes.imageSection}>
        <img src={whyIseCOllaborateWithUs} alt="Collaborate with us" />
      </div>
    </section>
  );
};

export default WhyIseCollaborateWithUs;
