import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./CourseDetailgetStarted.module.css";
import enroll from "../../Assets/Images/enroll.svg";
import selectPayment from "../../Assets/Images/selectPayment.svg";
import startForFree from "../../Assets/Images/startForFree.svg";
import Button from "../../Components/Button/Button";

type CourseDetailgetStartedProps = {
  routeToMainApp: () => void;
};

const CourseDetailgetStarted = ({
  routeToMainApp,
}: CourseDetailgetStartedProps) => {
  // Utils
  const getStartedDetails = [
    {
      image: startForFree,
      title: "Start for free",
      description:
        "Explore our schools and find the perfect free course that aligns with your goals and aspirations or enroll in a paid course for more comprehensive learning. ",
    },

    {
      image: enroll,
      title: "Enroll for main course",
      description:
        "Create an account, experience our easy application process, get accepted and unlock your personalized dashboard.",
    },

    {
      image: selectPayment,
      title: "Select payment plan",
      description:
        "Pay at once or split it into two instalments. Both options grant you access to all resources and features. Start learning after payment.",
    },
  ];
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader
          header="Get started today"
          paragraph="THE ADMISSION PROCESS"
          style={{ textAlign: "center" }}
        />
      </div>
      <div className={classes.detailSection}>
        {getStartedDetails.map((data, i) => {
          return (
            <div key={i}>
              <img src={data.image} alt={data.title} />
              <h6>{data.title}</h6>
              <p>{data.description}</p>
            </div>
          );
        })}
      </div>

      <div className={classes.buttonSection}>
        <Button
          onClick={() => {
            routeToMainApp();
          }}
        >
          Get started
        </Button>
      </div>
    </section>
  );
};

export default CourseDetailgetStarted;
