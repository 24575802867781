import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./HomePageWhatIsIse.module.css";
import homePageWhyIseImage from "../../Assets/Images/homePageWhyIseImage.svg";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

const HomePageWhatIsIse = () => {
  // Navigate
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader header="What is iṣẹ́ School" paragraph="WE ARE UNIQUE" />
      </div>
      <img src={homePageWhyIseImage} alt="Why Ise" loading="lazy" />
      <p>
        iṣẹ́ School, is a virtual learning platform designed for Africans to
        acquire technical and non-technical skills. We aim to equip individuals
        with the tools and knowledge to excel in remote work environments. We
        are building Africa's future, one resource at a time.
      </p>
      <div className={classes.buttonSection}>
        <Button
          onClick={() => {
            navigate("/why-ise-school");
            scrollToTheTop();
          }}
        >
          Learn about us
        </Button>
      </div>
    </section>
  );
};

export default HomePageWhatIsIse;
