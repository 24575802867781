import { headerNavItemsType } from "../../Utilities/navItems";
import classes from "./HeaderSideNav.module.css";
import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { headerNavItems } from "../../Utilities/navItems";

type HeaderSideNavProps = {
  closeSideNav: () => void;
};

const HeaderSideNav = ({ closeSideNav }: HeaderSideNavProps) => {
  // Location
  const location = useLocation();

  // States
  const [navItemsState, setNavItemsState] =
    useState<headerNavItemsType>(headerNavItems);

  return (
    <div className={classes.container}>
      <div className={classes.cancel}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          onClick={closeSideNav}
        >
          <path
            d="M6 18L18 6M6 6L18 18"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div className={classes.navItemsContainer}>
        {navItemsState.map((navItem, i) => {
          if (!navItem.route && navItem.otherOptions) {
            return (
              <React.Fragment key={i}>
                <div
                  className={classes.navItemOtherOptions}
                  onClick={() => {
                    const navItemsCopy = navItemsState.map((data, index) => {
                      if (i === index) {
                        return {
                          ...data,
                          otherOptions: {
                            ...data.otherOptions,
                            isActive: !data.otherOptions?.isActive,
                          },
                        };
                      } else {
                        return {
                          ...data,
                          otherOptions: {
                            ...data.otherOptions,
                            isActive: false,
                          },
                        };
                      }
                    });

                    setNavItemsState(navItemsCopy as headerNavItemsType);
                  }}
                >
                  <div className={classes.navItemOtherOptionsInner}>
                    <span>{navItem.title}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={
                        navItem.otherOptions.isActive
                          ? {
                              transform: "rotate(-90deg)",
                              transition: "all 0.3s ease-in-out",
                            }
                          : {
                              transform: "rotate(0deg)",
                              transition: "all 0.3s ease-in-out",
                            }
                      }
                    >
                      <path
                        d="M12.0008 14.9758C11.8674 14.9758 11.7381 14.9508 11.6128 14.9008C11.4874 14.8508 11.3834 14.7841 11.3008 14.7008L6.70078 10.1008C6.51745 9.91745 6.42578 9.68411 6.42578 9.40078C6.42578 9.11745 6.51745 8.88411 6.70078 8.70078C6.88411 8.51745 7.11745 8.42578 7.40078 8.42578C7.68411 8.42578 7.91745 8.51745 8.10078 8.70078L12.0008 12.6008L15.9008 8.70078C16.0841 8.51745 16.3174 8.42578 16.6008 8.42578C16.8841 8.42578 17.1174 8.51745 17.3008 8.70078C17.4841 8.88411 17.5758 9.11745 17.5758 9.40078C17.5758 9.68411 17.4841 9.91745 17.3008 10.1008L12.7008 14.7008C12.6008 14.8008 12.4924 14.8718 12.3758 14.9138C12.2591 14.9558 12.1341 14.9764 12.0008 14.9758Z"
                        fill="#2E2E2E"
                      />
                    </svg>
                  </div>

                  <div
                    className={classes.otherOptionsContainer}
                    style={
                      navItem.otherOptions.isActive
                        ? { maxHeight: "308px" }
                        : { maxHeight: "0px" }
                    }
                  >
                    <div className={classes.otherOptionsContainerInner}>
                      {navItem.otherOptions.data.map((otherOptions) => {
                        return (
                          <Link
                            to={otherOptions.route}
                            key={otherOptions.title}
                          >
                            {otherOptions.title}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <Link
                to={navItem.route as string}
                key={i}
                className={`${classes.navItems} ${
                  // navItem.route === location.pathname
                  //   ? classes.activeLink
                  //   : undefined

                  navItem.keywords?.find((data) => {
                    return location.pathname.slice(1).includes(data);
                  })
                    ? classes.activeLink
                    : undefined
                }`}
              >
                {navItem.title}
              </Link>
            );
          }
        })}
      </div>

      <div className={classes.buttonSection}>
        <div>
          <Button
            onClick={() => {
              window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-up`);
            }}
          >
            Enrol
          </Button>
        </div>
        <div>
          <Button
            type="secondary"
            onClick={() => {
              window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-in`);
            }}
          >
            Login
          </Button>
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 12C21 16.9706 16.9706 21 12 21M21 12C21 7.02944 16.9706 3 12 3M21 12H3M12 21C7.02944 21 3 16.9706 3 12M12 21C13.6569 21 15 16.9706 15 12C15 7.02944 13.6569 3 12 3M12 21C10.3431 21 9 16.9706 9 12C9 7.02944 10.3431 3 12 3M3 12C3 7.02944 7.02944 3 12 3"
              stroke="#2E2E2E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>EN</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12.0008 14.9758C11.8674 14.9758 11.7381 14.9508 11.6128 14.9008C11.4874 14.8508 11.3834 14.7841 11.3008 14.7008L6.70078 10.1008C6.51745 9.91745 6.42578 9.68411 6.42578 9.40078C6.42578 9.11745 6.51745 8.88411 6.70078 8.70078C6.88411 8.51745 7.11745 8.42578 7.40078 8.42578C7.68411 8.42578 7.91745 8.51745 8.10078 8.70078L12.0008 12.6008L15.9008 8.70078C16.0841 8.51745 16.3174 8.42578 16.6008 8.42578C16.8841 8.42578 17.1174 8.51745 17.3008 8.70078C17.4841 8.88411 17.5758 9.11745 17.5758 9.40078C17.5758 9.68411 17.4841 9.91745 17.3008 10.1008L12.7008 14.7008C12.6008 14.8008 12.4924 14.8718 12.3758 14.9138C12.2591 14.9558 12.1341 14.9764 12.0008 14.9758Z"
              fill="#2E2E2E"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default HeaderSideNav;
