import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./WhyIseOurStory.module.css";
import whyIseHeroImage from "../../Assets/Images/whyIseHeroImage.svg";

const WhyIseOurStory = () => {
  return (
    <section className={classes.container}>
      <div className={classes.imageSection}>
        <img src={whyIseHeroImage} alt="Our Story" />
      </div>
      <div className={classes.textSection}>
        <div className={classes.sectionHeader}>
          <SectionsHeader paragraph="WE ARE DIFFERENT" header="Our story" />
        </div>

        <p>
          At iṣẹ EdTech, we're all about helping Africans unlock their full
          potential by gaining essential technical and non-technical skills for
          remote work opportunities—locally and globally.
        </p>
        <p>
          At iṣẹ EdTech, we go beyond the usual software and design courses. Our
          schools and training are designed to broaden your career prospects,
          making you a valuable asset in the fast-paced global tech industry.
        </p>
        <p>
          We're dedicated to creating a vibrant community of individuals like
          yourself, all ready to excel in the dynamic world of remote
          collaborations. With every step we take, we're committed to shaping
          Africa's future, one resource at a time.
        </p>
        <p>
          So, why wait? Join us on this transformative journey, and together,
          let's unlock your potential and shape Africa's digital landscape for a
          brighter tomorrow!
        </p>
      </div>
    </section>
  );
};

export default WhyIseOurStory;
