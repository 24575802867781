import Breadcrumbs, { BreadcrumbsProps } from "../Breadcrumbs/Breadcrumbs";
import classes from "./CollaborateFormsLayout.module.css";
import Layout from "../Layout/Layout";

type CollaborateFormsLayoutProps = {
  children: React.ReactNode;
  breadCrumbProps: BreadcrumbsProps;
  header: string;
  paragraph: string;
};

const CollaborateFormsLayout = ({
  children,
  breadCrumbProps,
  header,
  paragraph,
}: CollaborateFormsLayoutProps) => {
  return (
    <Layout>
      <section className={classes.container}>
        <div>
          <Breadcrumbs {...breadCrumbProps} />
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={classes.header}>
            <h4>{header}</h4>
            <p>{paragraph}</p>
          </div>
          <div className={classes.formBody}>{children}</div>
        </form>
      </section>
    </Layout>
  );
};

export default CollaborateFormsLayout;
