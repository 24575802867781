import Layout from "../Components/Layout/Layout";
import HomePageAreYouConvinced from "../Containers/HomePageAreYouConvinced/HomePageAreYouConvinced";
import HomePageExperienceExcitingLearning from "../Containers/HomePageExperienceExcitingLearning/HomePageExperienceExcitingLearning";
import HomePageHeroSection from "../Containers/HomePageHeroSection/HomePageHeroSection";
import HomePageIseCourses from "../Containers/HomePageIseCourses/HomePageIseCourses";
import HomePagePartnerWithUs from "../Containers/HomePagePartnerWithUs/HomePagePartnerWithUs";
import HomePageWhatIsIse from "../Containers/HomePageWhatIsIse/HomePageWhatIsIse";
// import HomePageWhatPeopleSay from "../Containers/HomePageWhatPeopleSay/HomePageWhatPeopleSay";

const HomePage = () => {
  return (
    <Layout>
      <HomePageHeroSection />
      <HomePageWhatIsIse />
      <HomePageIseCourses />
      <HomePageExperienceExcitingLearning />
      <HomePagePartnerWithUs />
      {/* <HomePageWhatPeopleSay /> */}
      <HomePageAreYouConvinced />
    </Layout>
  );
};

export default HomePage;
