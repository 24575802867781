import { useContext } from "react";
import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { SchoolsContext } from "../../Context/SchoolsContext";
import classes from "./CourseDetailKickStart.module.css";

type CourseDetailKickStartProps = {
  routeToMainApp: () => void;
};

const CourseDetailKickStart = ({
  routeToMainApp,
}: CourseDetailKickStartProps) => {
  // Context
  const { iseCourseDetail } = useContext(SchoolsContext);

  // Navigate

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.header}>
          <SectionsHeader
            header={`Kickstart your career in ${
              iseCourseDetail?.data?.name || "this course"
            }`}
            paragraph="ARE YOU READY?"
            style={{ textAlign: "center" }}
          />
        </div>

        <div className={classes.buttonSection}>
          <Button type="secondary" disabled>
            Download curriculum
          </Button>
          <Button
            onClick={() => {
              routeToMainApp();
            }}
          >
            Get started
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CourseDetailKickStart;
