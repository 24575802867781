import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "./Containers/404/404";
import BecomeATutorForm from "./Containers/BecomeATutorForm/BecomeATutorForm";
import LaptopDonationForm from "./Containers/LaptopDonationForm/LaptopDonationForm";
import PartnershipApplicationForm from "./Containers/PartnershipApplicationForm/PartnershipApplicationForm";
import SponsorshipApplicationForm from "./Containers/SponsorshipApplicationForm/SponsorshipApplicationForm";
// import Blog from "./Pages/Blog";
// import BlogContent from "./Pages/BlogContent";
import CourseDetail from "./Pages/CourseDetail";
import FaqPage from "./Pages/FaqPage";
import HomePage from "./Pages/HomePage";
import SchoolOfBusiness from "./Pages/SchoolOfBusiness";
import SponsorAStudent from "./Pages/SponsorAStudent";
import WhyIseEdTech from "./Pages/WhyIseEdTech";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/:schoolSlug/:schoolId" element={<SchoolOfBusiness />} />
      <Route
        path="/:schoolSlug/:schoolId/:courseId"
        element={<CourseDetail />}
      />
      <Route path="/why-ise-school" element={<WhyIseEdTech />} />
      <Route path="/sponsor-a-student" element={<SponsorAStudent />} />
      <Route
        path="/sponsor-a-student/laptop-donation-form"
        element={<LaptopDonationForm />}
      />
      <Route
        path="/sponsor-a-student/sponsorship-application-form"
        element={<SponsorshipApplicationForm />}
      />
      <Route
        path="/become-a-tutor/tutor-application-form"
        element={<BecomeATutorForm />}
      />

      <Route path="/partner-with-us" element={<PartnershipApplicationForm />} />
      <Route path="/faqs" element={<FaqPage />} />
      {/* <Route path="/blog" element={<Blog />} /> */}
      {/* <Route path="/blog/:blogId" element={<BlogContent />} /> */}

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
