import { useContext } from "react";
import Button from "../../Components/Button/Button";
import { AppContext } from "../../Context/AppContext";
import classes from "./SchoolPagesHeroSection.module.css";

type SchoolPagesHeroProps = {
  heroImage: string;
  paragraph: string;
  header: string;
  subHeading: string;
  coursesRef?: any;
};

const SchoolPagesHeroSection = ({
  heroImage,
  header,
  subHeading,
  paragraph,
  coursesRef,
}: SchoolPagesHeroProps) => {
  // Context
  const { scrollTOView } = useContext(AppContext);
  return (
    <section className={classes.container}>
      <div className={classes.textSection}>
        <p>{paragraph}</p>
        <h1>{header}</h1>
        <p>{subHeading}</p>
        <div className={classes.buttonSection}>
          <Button onClick={scrollTOView}>Explore courses</Button>
        </div>
      </div>
      <div className={classes.imageSection}>
        <img src={heroImage} alt="Ise School of business" />
      </div>

      <div className={classes.buttonSection}>
        <Button onClick={scrollTOView}>Explore courses</Button>
      </div>
    </section>
  );
};

export default SchoolPagesHeroSection;
