import classes from "./CourseCard.module.css";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

type CourseCardProps = {
  title: string;
  image: string;
  description: string;
  duration: number | string;
  paid?: boolean | undefined;
  route?: string;
  id?: string | number;
  slug?: string;
  buttonText?: string;
};

const CourseCard = ({
  title,
  image,
  description,
  duration,
  paid,
  route,
  id,
  slug,
  buttonText,
}: CourseCardProps) => {
  // Navigate
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <img src={image} alt="School" />
      <div className={classes.textSection}>
        <h6>
          <span>{title}</span>
          {paid !== undefined && (
            <span
              style={
                paid
                  ? { background: "rgba(56, 204, 179, 1)" }
                  : { background: "rgba(255, 208, 41, 1)" }
              }
            >
              {paid ? "Paid" : "Free"}
            </span>
          )}
        </h6>
        <p>
          {description.length > 150
            ? `${description.slice(0, 150)}...`
            : description}
        </p>
        <p>{duration}</p>
        <div className={classes.buttonSection}>
          <Button
            onClick={() => {
              if (route) {
                navigate(route);
              } else {
                navigate(`/${slug?.trim()}/${id?.toString()}`);
              }
              scrollToTheTop();
            }}
          >
            <>
              <span> {buttonText || "Explore courses"} </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17 8L21 12M21 12L17 16M21 12L3 12"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
