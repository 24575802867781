export type headerNavItemsType = {
  title: string;
  route: string | null;
  keywords?: string[];
  otherOptions: {
    isActive: boolean;
    data: { title: string; route: string; isLive: boolean }[];
  } | null;
  isVisible: boolean;
}[];

export const headerNavItems: headerNavItemsType = [
  {
    title: "Home",
    route: "/",
    keywords: [""],
    otherOptions: null,
    isVisible: true,
  },
  {
    title: "Why iṣẹ́ School",
    route: "/why-ise-school",
    keywords: ["why-ise-school"],
    otherOptions: null,
    isVisible: true,
  },
  {
    title: "Schools",
    route: null,
    keywords: [
      "schools",
      "school-of-business",
      "school-of-engineering",
      "school-of-data",
      "school-of-clouds",
      "school-of-information-technology",
    ],
    otherOptions: {
      isActive: false,
      data: [],
    },
    isVisible: true,
  },
  {
    title: "Resources",
    route: null,
    keywords: ["resources"],
    otherOptions: {
      isActive: false,
      data: [
        {
          title: "Blog",
          route: "/blog",
          isLive: false,
        },

        {
          title: "FAQs",
          route: "/faqs",
          isLive: true,
        },
      ],
    },

    isVisible: true,
  },
  {
    title: "Collaborate",
    route: "/sponsor-a-student",
    keywords: ["collaborate", "sponsor-a-student"],
    otherOptions: null,

    isVisible: true,
  },
];
