import { useEffect, useState } from "react";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./SchoolPageTutors.module.css";
import linkedInIcon from "../../Assets/Icons/linkedInIcon.svg";
import xIcon from "../../Assets/Icons/xIcon.svg";

type SchoolPageTutorsProps = {
  schoolData: any;
};

const SchoolPageTutors = ({ schoolData }: SchoolPageTutorsProps) => {
  // State
  const [tutor, setTutor] = useState(schoolData?.tutors);
  const [activeIndex, setActiveIndex] = useState(0);

  //   Utils
  const activeTutor = (tutor.tutors as any[])?.find((data) => {
    return data.isActive;
  });

  const nextTutor = () => {
    if (activeIndex >= (tutor.tutors as any[])?.length - 1) {
      return;
    }
    setActiveIndex((prevState) => {
      return prevState + 1;
    });
  };

  const prevTutor = () => {
    if (activeIndex === 0) {
      return;
    }
    setActiveIndex((prevState) => {
      return prevState - 1;
    });
  };

  const activeTutorHandler = (index: number) => {
    const newSchoolData = (schoolData?.tutors as any[])?.map((data, i) => {
      if (i === index) {
        return { ...data, isActive: true };
      } else {
        return { ...data, isActive: false };
      }
    });

    setTutor((prevState: any) => {
      return { ...prevState, tutors: newSchoolData };
    });
  };

  useEffect(() => {
    if (schoolData.tutors) {
      const newSchoolData = (schoolData.tutors as any[])?.map((data, i) => {
        if (i === activeIndex) {
          return { ...data, isActive: true };
        } else {
          return { ...data, isActive: false };
        }
      });

      setTutor((prevState: any) => {
        return { ...prevState, tutors: newSchoolData };
      });
    }

    // eslint-disable-next-line
  }, [activeIndex]);

  return (
    <section className={classes.container}>
      <div>
        <SectionsHeader
          paragraph="THE TALENTS"
          header="Meet the tutors"
          style={{ textAlign: "center" }}
        />
      </div>
      <div
        className={`${classes.scrollLeft} ${
          activeIndex === 0 ? classes.inActiveScroll : classes.activeScroll
        }`}
        onClick={prevTutor}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15 19L8 12L15 5"
            stroke={activeIndex === 0 ? "#A79AFE" : "#fff"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div
        className={`${classes.scrollRight} ${
          activeIndex === (tutor.tutors as any[])?.length - 1
            ? classes.inActiveScroll
            : classes.activeScroll
        }`}
        onClick={nextTutor}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9 5L16 12L9 19"
            stroke={
              activeIndex === (tutor.tutors as any[])?.length - 1
                ? "#A79AFE"
                : "#fff"
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={classes.tutorDetails}>
        <div className={classes.imageSection}>
          <img src={activeTutor?.image} alt={activeTutor?.first_name} />
        </div>
        <div className={classes.textSection}>
          <p>
            {activeTutor?.first_name} {activeTutor?.last_name}
          </p>
          <p>{activeTutor?.occupation}</p>
          <p>{activeTutor?.description}</p>
          <div className={classes.socialsSections}>
            <img src={linkedInIcon} alt="LinkedIn" />
            <img src={xIcon} alt="X" />
          </div>
        </div>
      </div>

      <div className={classes.carouselSection}>
        {(tutor?.tutors as any[])?.map((data, i) => {
          return (
            <div
              className={data.isActive ? classes.active : classes.inActive}
              onClick={() => {
                activeTutorHandler(i);
              }}
              key={i}
            ></div>
          );
        })}
      </div>
    </section>
  );
};

export default SchoolPageTutors;
