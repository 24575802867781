import { useContext, useEffect, useState } from "react";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { SchoolsContext } from "../../Context/SchoolsContext";
import classes from "./SchoolPageOverviewSummary.module.css";

const SchoolPageOverviewSummary = () => {
  // context
  const { schoolsDetails } = useContext(SchoolsContext);

  // State
  const [overview, setOverview] = useState<{
    id: number;
    items: string[];
    title: string;
  } | null>(null);

  let schoolData = schoolsDetails?.data;

  useEffect(() => {
    if (schoolsDetails?.data) {
      setOverview(JSON.parse(schoolsDetails?.data?.overview));
    }
  }, [schoolsDetails?.data]);

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader
          header={schoolData?.name}
          paragraph="WHAT TO KNOW ABOUT"
        />
      </div>
      <div className={classes.listSection}>
        <p>{schoolData?.name}</p>
        <ul>
          {overview?.items?.map((data: any, i: number) => {
            return <li key={i}>{data}</li>;
          })}
        </ul>
      </div>
    </section>
  );
};

export default SchoolPageOverviewSummary;
