import React, { useContext, useEffect } from "react";
import CourseCard from "../../Components/CourseCard/CourseCard";
import LoadingContainer from "../../Components/LoadingContainer/LoadingContainer";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { AppContext } from "../../Context/AppContext";
import { SchoolsContext } from "../../Context/SchoolsContext";
import classes from "./HomePageIseCourses.module.css";

const HomePageIseCourses = () => {
  // Context
  const { coursesRef } = useContext(AppContext);
  const { schools, fetchSchools } = useContext(SchoolsContext);

  //   Effects
  useEffect(() => {
    fetchSchools();

    // eslint-disable-next-line
  }, []);

  return (
    <section className={classes.container} ref={coursesRef}>
      <SectionsHeader
        header="Take charge of your career path with our transformative virtual schools"
        paragraph="INNOVATIVE PROGRAMS, TAILORED FOR YOU"
      />
      <div className={classes.coursesSection}>
        {schools.isLoading ? (
          <LoadingContainer />
        ) : (
          schools.data?.map((data: any) => {
            return (
              <div key={data.id} className={classes.course}>
                <CourseCard
                  title={data?.name}
                  image={data.image}
                  description={data.description}
                  duration="4 months"
                  id={data.id}
                  slug={data.slug}
                />
              </div>
            );
          })
        )}
      </div>
    </section>
  );
};

export default HomePageIseCourses;
