import { useRef, useState, useEffect, useContext } from "react";
import classes from "./Header.module.css";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import { headerNavItems, headerNavItemsType } from "../../Utilities/navItems";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import HeaderSideNav from "../HeaderSideNav/HeaderSideNav";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";
import { SchoolsContext } from "../../Context/SchoolsContext";

const Header = () => {
  // Context
  const { schools } = useContext(SchoolsContext);
  // States
  const [navItemsState, setNavItemsState] =
    useState<headerNavItemsType>(headerNavItems);

  // Refs
  const sideNav = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // location
  const location = useLocation();
  const navigate = useNavigate();

  // Utils
  const openSideNav = () => {
    if (sideNav.current) {
      sideNav.current.style.width = "100%";
    }
  };

  const closeSideNav = () => {
    if (sideNav.current) {
      sideNav.current.style.width = "0%";
    }
  };

  useEffect(() => {
    const removeDropdownHandler = (e: any) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        const navItemsCopy = navItemsState.map((data) => {
          return {
            ...data,
            otherOptions: { ...data.otherOptions, isActive: false },
          };
        });

        setNavItemsState(navItemsCopy as headerNavItemsType);
      } else {
        return;
      }
    };

    document.addEventListener("mousedown", removeDropdownHandler);

    return () => {
      document.removeEventListener("mousedown", removeDropdownHandler);
    };

    // eslint-disable-next-line
  }, []);

  // Effects
  useEffect(() => {
    if (schools.data) {
      const newSchools: any[] = schools.data.map((data: any) => {
        return {
          isLive: true,
          title: data?.name,
          route: `/${data?.slug}/${data?.id}`,
          id: data?.id,
        };
      });

      (navItemsState[2].otherOptions as any).data = newSchools;
    }

    // eslint-disable-next-line
  }, [schools]);

  return (
    <section className={classes.container}>
      <div className={classes.logoSection}>
        <img
          src={iseLogo}
          alt="Ise"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className={classes.navItemsAndButtons}>
        {navItemsState.map((navItem, i) => {
          if (!navItem.route && navItem.otherOptions) {
            return (
              <div
                className={`${classes.navItemOtherOptions} ${
                  // navItem.keywords?.includes(location.pathname.slice(1))
                  //   ? classes.activeLink
                  //   : undefined
                  navItem.keywords?.find((data) => {
                    return location.pathname.slice(1).includes(data);
                  })
                    ? classes.activeLink
                    : undefined
                }`}
                key={i}
                onClick={() => {
                  const navItemsCopy = navItemsState.map((data, index) => {
                    if (i === index) {
                      return {
                        ...data,
                        otherOptions: {
                          ...data.otherOptions,
                          isActive: !data.otherOptions?.isActive,
                        },
                      };
                    } else {
                      return {
                        ...data,
                        otherOptions: { ...data.otherOptions, isActive: false },
                      };
                    }
                  });

                  setNavItemsState(navItemsCopy as headerNavItemsType);
                }}
              >
                <span>{navItem.title}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  style={
                    navItem.otherOptions.isActive
                      ? {
                          transform: "rotate(-90deg)",
                          transition: "all 0.3s ease-in-out",
                        }
                      : {
                          transform: "rotate(0deg)",
                          transition: "all 0.3s ease-in-out",
                        }
                  }
                >
                  <path
                    d="M12.0008 14.9758C11.8674 14.9758 11.7381 14.9508 11.6128 14.9008C11.4874 14.8508 11.3834 14.7841 11.3008 14.7008L6.70078 10.1008C6.51745 9.91745 6.42578 9.68411 6.42578 9.40078C6.42578 9.11745 6.51745 8.88411 6.70078 8.70078C6.88411 8.51745 7.11745 8.42578 7.40078 8.42578C7.68411 8.42578 7.91745 8.51745 8.10078 8.70078L12.0008 12.6008L15.9008 8.70078C16.0841 8.51745 16.3174 8.42578 16.6008 8.42578C16.8841 8.42578 17.1174 8.51745 17.3008 8.70078C17.4841 8.88411 17.5758 9.11745 17.5758 9.40078C17.5758 9.68411 17.4841 9.91745 17.3008 10.1008L12.7008 14.7008C12.6008 14.8008 12.4924 14.8718 12.3758 14.9138C12.2591 14.9558 12.1341 14.9764 12.0008 14.9758Z"
                    fill="#2E2E2E"
                  />
                </svg>
                {navItem.otherOptions.isActive && (
                  <div
                    className={classes.otherOptionsContainer}
                    ref={dropdownRef}
                  >
                    {navItem.otherOptions.data.map((otherOptions) => {
                      return (
                        <Link
                          to={otherOptions.route}
                          key={otherOptions.title}
                          onClick={scrollToTheTop}
                          style={
                            otherOptions?.isLive
                              ? { color: "#2e2e2e" }
                              : {
                                  color: "#b9b9b9",
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                          }
                        >
                          {otherOptions.title}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <Link
                to={navItem.route as string}
                onClick={scrollToTheTop}
                key={i}
                className={`${classes.navItems} ${
                  navItem.route === location.pathname ||
                  navItem.keywords?.includes(location.pathname)
                    ? classes.activeLink
                    : undefined
                }`}
              >
                {navItem.title}
              </Link>
            );
          }
        })}
      </div>
      <div className={classes.buttonSection}>
        <div>
          <Button
            onClick={() => {
              window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-up`);
            }}
          >
            Enrol
          </Button>
        </div>
        <div>
          <Button
            type="secondary"
            onClick={() => {
              window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-in`);
            }}
          >
            Login
          </Button>
        </div>

        {/* <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 12C21 16.9706 16.9706 21 12 21M21 12C21 7.02944 16.9706 3 12 3M21 12H3M12 21C7.02944 21 3 16.9706 3 12M12 21C13.6569 21 15 16.9706 15 12C15 7.02944 13.6569 3 12 3M12 21C10.3431 21 9 16.9706 9 12C9 7.02944 10.3431 3 12 3M3 12C3 7.02944 7.02944 3 12 3"
              stroke="#2E2E2E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>EN</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12.0008 14.9758C11.8674 14.9758 11.7381 14.9508 11.6128 14.9008C11.4874 14.8508 11.3834 14.7841 11.3008 14.7008L6.70078 10.1008C6.51745 9.91745 6.42578 9.68411 6.42578 9.40078C6.42578 9.11745 6.51745 8.88411 6.70078 8.70078C6.88411 8.51745 7.11745 8.42578 7.40078 8.42578C7.68411 8.42578 7.91745 8.51745 8.10078 8.70078L12.0008 12.6008L15.9008 8.70078C16.0841 8.51745 16.3174 8.42578 16.6008 8.42578C16.8841 8.42578 17.1174 8.51745 17.3008 8.70078C17.4841 8.88411 17.5758 9.11745 17.5758 9.40078C17.5758 9.68411 17.4841 9.91745 17.3008 10.1008L12.7008 14.7008C12.6008 14.8008 12.4924 14.8718 12.3758 14.9138C12.2591 14.9558 12.1341 14.9764 12.0008 14.9758Z"
              fill="#2E2E2E"
            />
          </svg>
        </div> */}
      </div>
      <div className={classes.sideNavIndicator}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          onClick={openSideNav}
        >
          <path
            d="M4 6.5H20M4 12.5H20M4 18.5H20"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={classes.sideNav} ref={sideNav}>
        <HeaderSideNav closeSideNav={closeSideNav} />
      </div>
    </section>
  );
};

export default Header;
