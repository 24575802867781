import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { SchoolsContext } from "../../Context/SchoolsContext";
import HomePageAreYouConvinced from "../HomePageAreYouConvinced/HomePageAreYouConvinced";
import SchoolPageCertificate from "../SchoolPageCertificate/SchoolPageCertificate";
import SchoolPageCourses from "../SchoolPageCourses/SchoolPageCourses";
import SchoolPageOverviewSummary from "../SchoolPageOverviewSummary/SchoolPageOverviewSummary";
import SchoolPageTutors from "../SchoolPageTutors/SchoolPageTutors";
import classes from "./SchoolPageOverView.module.css";

type SchoolPageOverViewProps = {
  coursesRef?: any;
};

const SchoolPageOverView = ({ coursesRef }: SchoolPageOverViewProps) => {
  // COntext
  const { schoolsDetails } = useContext(SchoolsContext);
  const { overviewRef, schoolCourseRef, tutorsRef, benefits } =
    useContext(AppContext);

  // Utils
  const schoolData = schoolsDetails.data;
  return (
    <section className={classes.container}>
      <div ref={overviewRef}>
        {schoolData?.overview && <SchoolPageOverviewSummary />}
      </div>
      <div ref={schoolCourseRef}>
        {schoolData?.courses && schoolData?.courses?.length > 0 && (
          <SchoolPageCourses coursesRef={coursesRef} />
        )}
      </div>
      <div ref={benefits}>
        <SchoolPageCertificate />
      </div>
      <div ref={tutorsRef}>
        {schoolData?.tutors.length > 0 && (
          <SchoolPageTutors schoolData={schoolData} />
        )}
      </div>
      <HomePageAreYouConvinced />
    </section>
  );
};

export default SchoolPageOverView;
