import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./WhyIseHeroSection.module.css";

const WhyIseHeroSection = () => {
  return (
    <section className={classes.container}>
      <SectionsHeader
        paragraph="WE ARE ISÉ"
        header="We are empowering remote talent "
        description="Our comprehensive approach to learning and growth makes us the best EdTech platform to kickstart your tech journeall-round talent for remote success. "
        style={{ textAlign: "center" }}
      />
    </section>
  );
};

export default WhyIseHeroSection;
