import Layout from "../Components/Layout/Layout";
import SpomsorAStudentOptions from "../Containers/SpomsorAStudentOptions/SpomsorAStudentOptions";
import SponsorAStudentFAQs from "../Containers/SponsorAStudentFAQs/SponsorAStudentFAQs";
import SponsorAStudentHeroSection from "../Containers/SponsorAStudentHeroSection/SponsorAStudentHeroSection";

const SponsorAStudent = () => {
  return (
    <Layout>
      <SponsorAStudentHeroSection />
      <SpomsorAStudentOptions />
      <SponsorAStudentFAQs />
    </Layout>
  );
};

export default SponsorAStudent;
