import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./HomePageExperienceExcitingLearning.module.css";
import whyIse1 from "../../Assets/Images/whyIse1.svg";
import whyIse2 from "../../Assets/Images/whyIse2.svg";
import whyIse3 from "../../Assets/Images/whyIse3.svg";
import whyIse4 from "../../Assets/Images/whyIse4.svg";
import buildTalents from "../../Assets/Images/buildTalents.svg";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

const HomePageExperienceExcitingLearning = () => {
  //   utils
  const whyIse: { image: string; title: string; description: string }[] = [
    {
      image: whyIse1,
      title: "Self-paced classes",
      description:
        "Take control of your education with self-paced classes that adapt to your schedule. Embrace collaborative learning, fostering well-rounded skills with your peers",
    },
    {
      image: whyIse2,
      title: "Remote and virtual classes",
      description:
        "Grow and enhance your knowledge at home with remote courses, virtual  events, and lectures.",
    },
    {
      image: whyIse3,
      title: "Continuous learning",
      description:
        "Embark on an unending  learning journey and personalised growth through collaboration, gamification, and interactive experiences. ",
    },
    {
      image: whyIse4,
      title: "Capstone projects",
      description:
        "Gain practical experience, boost your skills and confidence by participating in capstone projects at the end of the course. ",
    },
  ];

  // navigate
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div className={classes.firstContainer}>
        <div className={classes.leftSection}>
          <SectionsHeader
            header="Experience exciting learning"
            paragraph="TOGETHER WE MOVE FORWARD"
          />
          <div className={classes.buttonSection}>
            <Button
              onClick={() => {
                window.open("https://crispy-meme-4g5oq44.pages.github.io/");
              }}
            >
              Enrol
            </Button>
          </div>
        </div>

        <div className={classes.rightSection}>
          {whyIse.map((data, i) => {
            return (
              <div key={i} className={classes.whyIse}>
                <img src={data.image} alt={data.title} />
                <h4>{data.title}</h4>
                <p>{data.description}</p>
              </div>
            );
          })}
        </div>

        <div className={classes.buttonSection}>
          <Button
            onClick={() => {
              window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-in`);
            }}
          >
            Enrol
          </Button>
        </div>
      </div>
      <div className={classes.secondContainer}>
        <div className={classes.secondLeftSection}>
          <img src={buildTalents} alt="Teach to empower" />
        </div>
        <div className={classes.secondRightSection}>
          <SectionsHeader
            header="Build talents"
            paragraph="TEACH TO EMPOWER"
            description="Share your expertise and inspire the next generation of professionals. Your experience and knowledge can help transform someone's career. Invest in someone's future by becoming a tutor today."
          />
          <div className={classes.buttonSection}>
            <Button
              onClick={() => {
                scrollToTheTop();
                navigate("/become-a-tutor/tutor-application-form");
              }}
            >
              Become a tutor
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageExperienceExcitingLearning;
