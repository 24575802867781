import Layout from "../Components/Layout/Layout";
import WhyIseCollaborateWithUs from "../Containers/WhyIseCollaborateWithUs/WhyIseCollaborateWithUs";
import WhyIseHeroSection from "../Containers/WhyIseHeroSection/WhyIseHeroSection";
// import WhyIseMeetTheTeam from "../Containers/WhyIseMeetTheTeam/WhyIseMeetTheTeam";
import WhyIseOurStory from "../Containers/WhyIseOurStory/WhyIseOurStory";
import WhyIseWhatWeOffer from "../Containers/WhyIseWhatWeOffer/WhyIseWhatWeOffer";

const WhyIseEdTech = () => {
  return (
    <Layout>
      <WhyIseHeroSection />
      <WhyIseOurStory />
      <WhyIseWhatWeOffer />
      {/* <WhyIseMeetTheTeam /> */}
      <WhyIseCollaborateWithUs />
    </Layout>
  );
};

export default WhyIseEdTech;
