import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { SchoolsContext } from "../../Context/SchoolsContext";
import { activeToggler2 } from "../../HelperFunctions/activeTogglers";
import classes from "./CourseDetailWhatYoullLearn.module.css";

const CourseDetailWhatYoullLearn = () => {
  // Context
  const { getCourseModulesObject } = useContext(SchoolsContext);

  const [topics, setTopics] = useState<any[]>(
    (getCourseModulesObject?.data as any[])?.map((data, i) => {
      if (i === 0) {
        return { ...data, isActive: true };
      } else {
        return { ...data, isActive: false };
      }
    })
  );

  useEffect(() => {
    setTopics(
      (getCourseModulesObject?.data as any[])?.map((data, i) => {
        if (i === 0) {
          return { ...data, isActive: true };
        } else {
          return { ...data, isActive: false };
        }
      })
    );
  }, [getCourseModulesObject.data]);

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader
          header="What you’ll learn"
          paragraph="STRUCTURED CURRICULUM"
          description="Embark on a transformative learning journey as you navigate through our meticulously crafted and thoughtfully structured curriculum."
        />
        <Button disabled>Download Curriculum</Button>
      </div>
      <p className={classes.explore}>EXPLORE THE TOPICS</p>
      {topics?.length > 0 && (
        <div className={classes.topicsSection}>
          <ol className={classes.leftSection}>
            {topics?.length > 0 &&
              topics?.map((data, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      activeToggler2(i, topics, setTopics);
                    }}
                    className={
                      data.isActive ? classes.active : classes.inActive
                    }
                  >
                    {data?.title}
                  </li>
                );
              })}
          </ol>
          <div className={classes.rightSection}>
            <p>
              {
                topics?.find?.((data) => {
                  return data?.isActive;
                })?.title
              }
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  topics?.length > 0 &&
                  topics?.find((data) => {
                    return data.isActive;
                  })?.description,
              }}
            >
              {/* {topics?.length > 0 &&
                topics?.find((data) => {
                  return data.isActive;
                })?.description} */}
            </p>
            {topics.length > 0 &&
              topics
                ?.find((data) => {
                  return data.isActive;
                })
                ?.course_weeks?.map((lesson: any, j: number) => {
                  return (
                    <div key={j} className={classes.lesson}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 13L9 17L19 7"
                            stroke="#664EFE"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span>{lesson?.title}</span>
                    </div>
                  );
                })}
          </div>
        </div>
      )}
    </section>
  );
};

export default CourseDetailWhatYoullLearn;
