import classes from "./SchoolPageCertificate.module.css";
import schoolPageCertificate from "../../Assets/Images/schoolPageCertificate.svg";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";

const SchoolPageCertificate = () => {
  return (
    <section className={classes.container}>
      <div className={classes.upperSection}>
        <img src={schoolPageCertificate} alt="School Certificate" />
        <div>
          <SectionsHeader
            paragraph="INDUSTRY RECOGNIZED"
            header="Earn a certificate"
            description="Gain industry recognition for your skills and knowledge with a valuable certificate. This credential validates your expertise and boosts your career prospects. Stand out from the crowd and make a lasting impact in the industry."
          />
        </div>
      </div>

      <div className={classes.lowerSection}>
        <div>
          <SectionsHeader
            paragraph="GET JOB READY"
            header="Build a portfolio"
            description="Make a lasting impression on potential employers with your portfolio. Showcase your achievements, practical skills, and expertise confidently. Let your portfolio speak volumes about your capabilities, taking your professional journey to new heights."
          />
        </div>
        <div>
          <div>
            <h4>Master practical skills</h4>
            <p>
              Develop essential practical skills with our immersive programs
              that combine hands-on training, real-world projects, and expert
              guidance. Gain the confidence and competence to excel in your
              industry, ready to tackle any professional challenge that comes
              your way.
            </p>
          </div>

          <div>
            <h4>Complete capstone project</h4>
            <p>
              Immerse yourself in real-world business scenarios and elevate your
              skills through engaging capstone projects. Gain invaluable
              hands-on experience that prepares you for success in the dynamic
              business landscape.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SchoolPageCertificate;
