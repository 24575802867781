import classes from "../BecomeATutorForm/BecomeATutorForm.module.css";
import collaborateBreadcrumbImage from "../../Assets/Icons/collaborateBreadcrumbImage.svg";
import CollaborateFormsLayout from "../../Components/CollaborateFormsLayout/CollaborateFormsLayout";
import Input from "../../Components/Input/Input";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import Button from "../../Components/Button/Button";
import { useContext, useEffect, useState } from "react";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import ApplicationSuccessfulBody from "../PartnershipApplicationForm/ApplicationSuccessfulBody";
import { FormsContext } from "../../Context/FormsContext";

const LaptopDonationForm = () => {
  // Context
  const {
    laptopDonationFormData,
    setLaptopDonationFormData,
    postDonationFormData,
    postDonationFormRequest,
    setPostDOnationFormRequest,
  } = useContext(FormsContext);

  // Utils
  const breadCrumbProps = {
    image: collaborateBreadcrumbImage,
    array: [
      { title: "Sponsorship", route: "/sponsor-a-student" },
      {
        title: "Laptop donation form",
        route: "/sponsor-a-student/laptop-donation-form",
      },
    ],
  };

  const [laptopAge, setLaptopAge] = useState("");
  const [laptopAccessories, setLaptopAccessories] = useState("");
  const [laptopCondition, setLaptopCondition] = useState("");
  const [laptopBrand, setLaptopBrand] = useState("");
  const [laptopModel, setLaptopModel] = useState("");
  const [contactmedium, setcontactMedium] = useState("");

  const formChangeHandler = (e: any) => {
    setLaptopDonationFormData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  // Effect
  useEffect(() => {
    setLaptopDonationFormData((prevState: any) => {
      return { ...prevState, laptop_age: laptopAge };
    });

    // eslint-disable-next-line
  }, [laptopAge]);

  useEffect(() => {
    if (laptopAccessories)
      setLaptopDonationFormData((prevState: any) => {
        return { ...prevState, laptop_accessories: laptopAccessories };
      });

    // eslint-disable-next-line
  }, [laptopAccessories]);

  useEffect(() => {
    if (laptopCondition)
      setLaptopDonationFormData((prevState: any) => {
        return { ...prevState, laptop_current_condition: laptopCondition };
      });

    // eslint-disable-next-line
  }, [laptopCondition]);

  useEffect(() => {
    if (laptopBrand)
      setLaptopDonationFormData((prevState: any) => {
        return { ...prevState, laptop_brand: laptopBrand };
      });

    // eslint-disable-next-line
  }, [laptopBrand]);

  useEffect(() => {
    if (laptopModel)
      setLaptopDonationFormData((prevState: any) => {
        return { ...prevState, laptop_model: laptopModel };
      });

    // eslint-disable-next-line
  }, [laptopModel]);

  useEffect(() => {
    if (contactmedium)
      setLaptopDonationFormData((prevState: any) => {
        return { ...prevState, contact_medium: contactmedium };
      });

    // eslint-disable-next-line
  }, [contactmedium]);

  return (
    <CollaborateFormsLayout
      breadCrumbProps={breadCrumbProps}
      header="Foster growth through laptop donations"
      paragraph="Your laptop donation enables tech education and empowers young minds. Together, we create opportunities for a brighter future"
    >
      <section className={classes.container}>
        {postDonationFormRequest?.data && (
          <AcceptedModal
            body={<ApplicationSuccessfulBody />}
            onClick={() => {
              setPostDOnationFormRequest((prevState) => {
                return { ...prevState, data: null };
              });
            }}
          />
        )}
        <Input
          label="Full name"
          isRequired
          placeholder="e.g John Doe"
          errorMessage="Please check the name and try again"
          name="fullname"
          onChange={formChangeHandler}
          value={laptopDonationFormData.fullname}
        />
        <Input
          label="Email address"
          isRequired
          placeholder="e.g yourname@gmail.com"
          type="email"
          errorMessage="Incorrect format. Try name@gmail.com"
          name="email"
          onChange={formChangeHandler}
          value={laptopDonationFormData.email}
        />
        <Input
          label="Phone number"
          placeholder="e.g 0906xxxx456"
          type="phone"
          errorMessage="Incorrect format. Try name@gmail.com"
          name="phone"
          onChange={formChangeHandler}
          value={laptopDonationFormData.phone}
        />

        <DropdownWithSearch
          label="How old is the laptop (in months or years)?"
          isRequired
          options={["1- 11months", "1-3years", "Above 3 years"]}
          title="Select option"
          selected={laptopAge}
          setSelected={setLaptopAge}
        />

        <DropdownWithSearch
          label="Are there accessories with the laptop?"
          options={[
            "No, only the laptop",
            "Yes, laptop and original charger",
            "Yes, laptop, charger and laptop stand",
            "Other",
          ]}
          title="Select option"
          selected={laptopAccessories}
          setSelected={setLaptopAccessories}
        />
        <DropdownWithSearch
          label="What is the current condition of the Laptop?"
          isRequired
          options={[
            "All good, no issues",
            "Battery life is not so strong ",
            "Charging port is bad ",
            "Broken screen ",
            "Some buttons don't work ",
            "Other",
          ]}
          title="Select option"
          selected={laptopCondition}
          setSelected={setLaptopCondition}
        />

        <DropdownWithSearch
          label="What is the brand of the laptop you would like to donate?"
          isRequired
          options={["HP", "Toshiba"]}
          title="Select option"
          selected={laptopBrand}
          setSelected={setLaptopBrand}
        />

        <DropdownWithSearch
          label="What is the model of the laptop you would like to donate?"
          isRequired
          options={[
            "Apple Mac ",
            "HP ",
            "Acer ",
            "Lenovo ",
            "Asus",
            "Samsung",
            "Other",
          ]}
          title="Select option"
          selected={laptopModel}
          setSelected={setLaptopModel}
        />

        <DropdownWithSearch
          label="How do you want us to reach you?"
          isRequired
          options={["Phone", "Social Media", "Email"]}
          title="Select"
          selected={contactmedium}
          setSelected={setcontactMedium}
        />

        <div className={classes.buttonSection}>
          <Button
            onClick={() => {
              postDonationFormData();
            }}
            loading={postDonationFormRequest?.isLoading}
          >
            Submit
          </Button>
        </div>
      </section>
    </CollaborateFormsLayout>
  );
};

export default LaptopDonationForm;
