import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./CourseDetailLEarningRequirements.module.css";

const CourseDetailLEarningRequirements = () => {
  const requirements = [
    {
      title: "Educational background and tech literacy",
      subRequirements: [
        "The program is open to anyone with no prerequisites or prior experience.",
        "Basic tech literacy is necessary to utilize tools like Google Suite.",
        "You need the motivation, grit and commitment to transform your career.",
      ],
    },

    {
      title: "Language proficiency",
      subRequirements: [
        "Applicants should have a basic understanding of English to engage with the curriculum and participate in discussions effectively.",
      ],
    },

    {
      title: "Live class participation:",
      subRequirements: ["Mandatory availability for live classes"],
    },
    {
      title: "Device and connectivity:",
      subRequirements: [
        "A computer (macOS, Windows, or Linux) with a webcam, microphone, and an internet connection.",
      ],
    },
    {
      title: "Time commitment:",
      subRequirements: [
        "Applicants must dedicate at least 1 hour and 30 minutes daily to the learning process.",
      ],
    },
  ];
  return (
    <section className={classes.container}>
      <SectionsHeader
        header="Learning requirements"
        paragraph="WHAT YOU NEED"
      />
      <ol className={classes.textContainer}>
        {requirements.map((data, i) => {
          return (
            <li key={i}>
              <h6>{data.title}</h6>
              <ul>
                {data.subRequirements.map((datum, j) => {
                  return <li key={j}> {datum}</li>;
                })}
              </ul>
            </li>
          );
        })}
      </ol>
    </section>
  );
};

export default CourseDetailLEarningRequirements;
