import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "./AboutUsFaq.module.css";

const AboutUsFaq = () => {
  const faqs = [
    {
      question:
        "Is iṣẹ́ EdTech's content accessible to an international audience?",
      answer:
        "While our primary goal is to help Africans, our courses are designed to appeal to everyone who wants to gain technical and non-technical skills.",
    },
    {
      question: "When was iṣẹ́ EdTech founded?",
      answer:
        "ise Edtech was founded in iṣẹ́ EdTech was founded on the 15th of July, 2022.",
    },
    {
      question: "What are the goals of iṣẹ́ EdTech?",
      answer:
        "We aim to equip individuals with the tools and knowledge to excel in remote work environments. We want to create a generation of tech minds with technical and non-technical skills. With each resource, we train, support and build the future of remote work in Africa. ",
    },
    {
      question: "How does iṣẹ́ EdTech's platform function to support learners?",
      answer:
        "Our user-friendly platform is designed to keep you motivated throughout your learning journey. We provide continuous support and offer badges and rewards for a fun and engaging learning experience. ",
    },
    {
      question: "What is iṣẹ́ EdTech?",
      answer: "What is iṣẹ́ EdTech? ",
    },
  ];

  return (
    <>
      {faqs.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown question={data.question} answer={data.answer} />
          </div>
        );
      })}
    </>
  );
};

export default AboutUsFaq;
