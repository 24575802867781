import classes from "../BecomeATutorForm/BecomeATutorForm.module.css";
import collaborateBreadcrumbImage from "../../Assets/Icons/collaborateBreadcrumbImage.svg";
import CollaborateFormsLayout from "../../Components/CollaborateFormsLayout/CollaborateFormsLayout";
import Input from "../../Components/Input/Input";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import Button from "../../Components/Button/Button";
import TextArea from "../../Components/TextArea/TextArea";
import FileUploadInput from "../../Components/FileUploadInput/FileUploadInput";
import { useContext, useEffect, useState } from "react";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import ApplicationSuccessfulBody from "./ApplicationSuccessfulBody";
import { FormsContext } from "../../Context/FormsContext";

const PartnershipApplicationForm = () => {
  // Context
  const {
    partnershipFormData,
    setPartnershipFormData,
    postPartnershipFormData,
    setPostPartnershipFormRequest,
    partnershioFormRequest,
  } = useContext(FormsContext);

  // Utils
  const breadCrumbProps = {
    image: collaborateBreadcrumbImage,
    array: [
      { title: "Home", route: "/" },
      {
        title: "Partnership application form",
        route: "/partner-with-us",
      },
    ],
  };

  const formChangeHandler = (e: any) => {
    setPartnershipFormData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  //   States
  const [partnershipType, setpartnershipType] = useState("");
  const [contactMedium, setCOntactMedium] = useState("");

  // Effects
  useEffect(() => {
    if (partnershipType)
      setPartnershipFormData((prevState) => {
        return { ...prevState, partnershipType: partnershipType };
      });

    if (contactMedium)
      setPartnershipFormData((prevState) => {
        return { ...prevState, contact_medium: contactMedium };
      });

    // eslint-disable-next-line
  }, [partnershipType, contactMedium]);

  return (
    <CollaborateFormsLayout
      breadCrumbProps={breadCrumbProps}
      header="Collaborate with us in shaping tech talents"
      paragraph="Partner with us to nurture future tech talents. Support their dreams through financial, technical, or employment assistance."
    >
      <section className={classes.container}>
        {partnershioFormRequest?.data && (
          <AcceptedModal
            body={<ApplicationSuccessfulBody />}
            onClick={() => {
              setPostPartnershipFormRequest((prevState) => {
                return { ...prevState, data: null };
              });
            }}
          />
        )}
        <Input
          label="Full name"
          isRequired
          placeholder="e.g John Doe"
          errorMessage="Please check the name and try again"
          value={partnershipFormData.fullname}
          name="fullname"
          onChange={formChangeHandler}
        />
        <Input
          label="Company name"
          isRequired
          placeholder="e.g ABC & Co"
          errorMessage="Please check the name and try again"
          value={partnershipFormData.companyName}
          name="companyName"
          onChange={formChangeHandler}
        />
        <Input
          label="Email address"
          isRequired
          placeholder="e.g yourname@gmail.com"
          type="email"
          errorMessage="Incorrect format. Try name@gmail.com"
          value={partnershipFormData.email}
          name="email"
          onChange={formChangeHandler}
        />
        <Input
          label="Phone number"
          placeholder="e.g 0906xxxx456"
          type="phone"
          errorMessage="Incorrect format. Try name@gmail.com"
          value={partnershipFormData.phone}
          name="phone"
          onChange={formChangeHandler}
        />
        <Input
          label="Website URL"
          placeholder="Enter website link"
          errorMessage="Please check the name and try again"
          value={partnershipFormData.website}
          name="website"
          onChange={formChangeHandler}
        />

        <DropdownWithSearch
          label="Partnership type"
          options={["Full", "Partial"]}
          title="Select type"
          selected={partnershipType}
          setSelected={setpartnershipType}
        />

        <TextArea
          label="Partnership objective"
          placeholder="Let us know your objective/goals for this partnership"
          value={partnershipFormData.partnershipObjective}
          name="partnershipObjective"
          onChange={formChangeHandler}
        />

        <FileUploadInput
          label="Attachments"
          placeholder="Kindly attach any relevant document  "
          onChange={(e: any) => {
            setPartnershipFormData((prevState: any) => {
              return {
                ...prevState,
                attachments: e.target.files[0],
              };
            });
          }}
        />

        <DropdownWithSearch
          label="How do you want us to reach you?"
          isRequired
          options={["Phone", "Social Media"]}
          title="Select"
          selected={contactMedium}
          setSelected={setCOntactMedium}
        />

        <div className={classes.buttonSection}>
          <Button
            onClick={() => {
              postPartnershipFormData();
            }}
            loading={partnershioFormRequest.isLoading}
          >
            Submit
          </Button>
        </div>
      </section>
    </CollaborateFormsLayout>
  );
};

export default PartnershipApplicationForm;
