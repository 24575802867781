import classes from "../AboutUsFaq/AboutUsFaq.module.css";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";

const faqs = [
  {
    question: "Which schools are available on iṣẹ́ EdTech?",
    answer:
      "iṣẹ́ EdTech has five schools: School of Engineering, School of Data, School of Business, School of Cloud, and School of IT. Each school offers at least two courses. ",
  },
  {
    question:
      "Are the courses tailored for beginners or suitable for experienced professionals too?",
    answer:
      "Our courses are for beginners trying to gain both technical and non-technical to start building their remote careers. ",
  },
  {
    question: "What is the typical duration of the courses?",
    answer:
      "Every paid course lasts for four months. The free programs are for one week. ",
  },
  {
    question: "Are the schools offered in-person or through virtual learning?",
    answer: "We offer virtual learning schools. ",
  },
];

const SchoolsFaq = () => {
  return (
    <>
      {faqs.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown question={data.question} answer={data.answer} />
          </div>
        );
      })}
    </>
  );
};

export default SchoolsFaq;
