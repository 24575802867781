import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import requestHandler from "../HelperFunctions/requestHandler";
import { backend_url } from "../Utilities/global";

type SchoolsContextProviderProps = {
  children: React.ReactNode;
};

export type requestType = {
  isLoading: boolean;
  data: null | any;
  error: null | any;
};

type SchoolsContextValueTypes = {
  schools: requestType;
  fetchSchools: () => void;
  fetchSchoolById: (id: string) => void;
  schoolsDetails: requestType;
  getCoursesById: (id: string) => void;
  iseCourseDetail: requestType;
  getCourseTopics: (id: string) => void;
  getCourseModulesObject: requestType;
};

export const SchoolsContext = createContext({} as SchoolsContextValueTypes);

const SchoolsContextProvider = ({ children }: SchoolsContextProviderProps) => {
  // Context
  const { setNotifications } = useContext(AppContext);

  // States
  const [schools, setSchools] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [schoolsDetails, setSchoolsDetails] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  //   Requests
  const fetchSchools = () => {
    if (!schools?.data)
      setSchools({ isLoading: true, data: null, error: null });
    axios
      .get(`${backend_url}/api/ise/v1/school`)
      .then((res) => {
        setSchools({ data: res?.data, error: null, isLoading: false });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response ? err.response.data.error.responseMessage : err.message
        );
        setSchools({
          data: null,
          error: err?.response
            ? err.response.data.error.responseMessage
            : err.message,
          isLoading: false,
        });
      });
  };

  const fetchSchoolById = (id: string) => {
    setSchoolsDetails({
      error: null,
      isLoading: true,
      data: null,
    });
    axios
      .get(`${backend_url}/api/ise/v1/school/landing-page/${id}`)
      .then((res) => {
        setSchoolsDetails({ data: res?.data, error: null, isLoading: false });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response ? err.response.data.error.responseMessage : err.message
        );
        setSchoolsDetails({
          data: null,
          error: err.response
            ? err.response.data.error.responseMessage
            : err.message,
          isLoading: false,
        });
      });
  };

  const [iseCourseDetail, setIseCourseDetail] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const getCoursesById = (id: string) => {
    if (id) {
      setIseCourseDetail({ isLoading: true, data: null, error: null });

      requestHandler({
        method: "GET",
        url: `${backend_url}/api/ise/v1/courses/${id}`,
      })
        .then((res) => {
          setIseCourseDetail({
            isLoading: false,
            data: (res as any).data,
            error: null,
          });
        })
        .catch((err) => {
          setIseCourseDetail({
            isLoading: false,
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
          });
        });
    }
  };

  const [getCourseModulesObject, setGetCourseModulesObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const getCourseTopics = (id: string) => {
    setGetCourseModulesObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/course-modules/landing-page/${id}`,
    })
      .then((res) => {
        setGetCourseModulesObject({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setGetCourseModulesObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });
      });
  };

  useEffect(() => {
    fetchSchools();

    // eslint-disable-next-line
  }, []);

  return (
    <SchoolsContext.Provider
      value={{
        schools,
        fetchSchools,
        fetchSchoolById,
        schoolsDetails,
        getCoursesById,
        iseCourseDetail,
        getCourseTopics,
        getCourseModulesObject,
      }}
    >
      {children}
    </SchoolsContext.Provider>
  );
};

export default SchoolsContextProvider;
