import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./HomePageAreYouConvinced.module.css";

const HomePageAreYouConvinced = () => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader
          paragraph="ARE WE WINNING YOU OVER?"
          header="Kickstart your career today"
          description="With our expert instructors, personalized mentorships and hands-on training, you are on your way to achieving your tech career goals. It’s time to go big!"
          style={{ textAlign: "center" }}
          headerIsBlack={true}
        />
      </div>
      <div className={classes.buttonSection}>
        <Button
          onClick={() => {
            window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-up`);
          }}
        >
          Enrol
        </Button>
      </div>
    </div>
  );
};

export default HomePageAreYouConvinced;
