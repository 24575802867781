import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "../AboutUsFaq/AboutUsFaq.module.css";

const CoursesFaq = () => {
  const faqs = [
    {
      question: "Are the courses self-paced, or do they have fixed schedules?",
      answer:
        "Yes, the courses are self-paced, but they have a duration of 4 months.",
    },
    {
      question: "What is the duration of the courses?",
      answer: "Each course lasts for 4 months",
    },
    {
      question: "Can I access course materials after I complete the course?",
      answer:
        "Yes. You can always have access to your paid courses on your dashboard.",
    },
    {
      question: " How do I track my progress and performance in a course?",
      answer:
        "Your dashboard provides visual progress tracking and insights into your learning achievements. Additionally, you earn rewards as you achieve milestones in your learning journey.",
    },
  ];

  return (
    <>
      {faqs.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown question={data.question} answer={data.answer} />
          </div>
        );
      })}
    </>
  );
};

export default CoursesFaq;
