import Button from "../../Components/Button/Button";
import classes from "../BecomeATutorForm/BecomeATutorForm.module.css";
import collaborateBreadcrumbImage from "../../Assets/Gifs/success.gif";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

const ApplicationSuccessfulBody = () => {
  // Navigate
  const navigate = useNavigate();
  return (
    <section className={classes.modalContainer}>
      <img src={collaborateBreadcrumbImage} alt="Application successful" />
      <h4>Application Successful!</h4>
      <p>
        We have received your submission, we will reach out to you in 24-48
        hours.{" "}
      </p>
      <div className={classes.modalButtonSection}>
        <Button
          onClick={() => {
            scrollToTheTop();
            navigate("/");
          }}
        >
          Go to home
        </Button>
      </div>
    </section>
  );
};

export default ApplicationSuccessfulBody;
