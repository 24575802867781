import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "../AboutUsFaq/AboutUsFaq.module.css";

const TutionFaq = () => {
  const faqs = [
    {
      question: "How much does tuition cost on iṣẹ́ EdTech? ",
      answer:
        " Our tuition fee is course specific, starting from 60,000 Naira ",
    },
    {
      question: "What payment model does iṣẹ́ EdTech use?",
      answer:
        "We use a pay-per-course model. Once you pay for a course, you have access to the entire course.",
    },
    {
      question: "What payment methods are acceptable for tuition fees?",
      answer: "We accept card transactions with Verve, Visa or Master cards.",
    },
    {
      question:
        " Is there a refund policy for tuition fees if I need to cancel or withdraw from a course?",
      answer:
        "We have a flexible refund policy depending on the timing of the request. Requests made one week after enrollment would get a 50% refund. Requests made after a month of enrolment would get a 20% refund. Please note that instalment payments are non-refundable. ",
    },
  ];

  return (
    <>
      {faqs.map((data, i) => {
        return (
          <div key={i} className={classes.faq}>
            <FAQDropdown question={data.question} answer={data.answer} />
          </div>
        );
      })}
    </>
  );
};

export default TutionFaq;
