import { Dispatch, SetStateAction } from "react";

type activeToggerParamTypes = {
  index: number;
  initState: any[];
  setState: Dispatch<SetStateAction<any[]>>;
};

export const activeToggler = ({
  index,
  initState,
  setState,
}: activeToggerParamTypes) => {
  const stateCopy = initState.map((data, i) => {
    if (i === index) {
      return { ...data, isActive: !data.isActive };
    } else {
      return { ...data, isActive: false };
    }
  });

  setState(stateCopy);
};

export const activeToggler2 = (
  index: number,
  initState: any[],
  setState: Dispatch<SetStateAction<any[]>>
) => {
  const stateCopy = initState.map((data, i) => {
    if (i === index) {
      return { ...data, isActive: !data.isActive };
    } else {
      return { ...data, isActive: false };
    }
  });

  setState(stateCopy);
};
