import Button from "../../Components/Button/Button";
import classes from "./HomePageHeroSection.module.css";
import heroImage from "../../Assets/Images/heroImage.svg";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";

const HomePageHeroSection = () => {
  // Context
  const { scrollTOView } = useContext(AppContext);
  return (
    <section className={classes.container}>
      <div className={classes.textSection}>
        <h1>
          Unlock your full potential through technical expertise and soft
          skills.
        </h1>
        <p>
          With our expert instructors and innovative resources you can level up
          and become a tech superstar. No more playing small – it's time to go
          big or go home!
        </p>
        <div className={classes.buttonSection}>
          <Button onClick={scrollTOView}>Explore virtual schools</Button>
        </div>
      </div>
      <div className={classes.imageSection}>
        <img src={heroImage} alt="Hero section Illustration" />
      </div>

      <div className={classes.paragraphSection}>
        With our expert instructors and innovative resources you can level up
        and become a tech superstar. No more playing small – it's time to go big
        or go home!
      </div>

      <div className={classes.buttonSection}>
        <Button onClick={scrollTOView}>Explore virtual schools</Button>
      </div>
    </section>
  );
};

export default HomePageHeroSection;
