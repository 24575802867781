import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";
import classes from "./HomePagePartnerWithUs.module.css";

const HomePagePartnerWithUs = () => {
  // Navigate
  const navigate = useNavigate();
  return (
    <section className={classes.container}>
      <p>SUPPORT THE FUTURE</p>
      <h4>Join us in shaping the future of tech talents in Africa!</h4>
      <p>
        Are you a company, individual or businesses looking to join our mission
        to empower learners by sharing your expertise and nurturing a solid
        foundation.{" "}
      </p>
      <div className={classes.buttonSection}>
        <Button
          type="tertiary"
          onClick={() => {
            scrollToTheTop();
            navigate("partner-with-us");
          }}
        >
          Partner with us
        </Button>
      </div>
    </section>
  );
};

export default HomePagePartnerWithUs;
