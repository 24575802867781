import { Dispatch, SetStateAction } from "react";
import SchoolPagesHeroSection from "../../Containers/SchoolPagesHeroSection/SchoolPagesHeroSection";
import SchoolPageOverView from "../../Containers/SchooPageOverView/SchoolPageOverView";
import Layout from "../Layout/Layout";
import SectionsNav from "../SectionsNav/SectionsNav";
import classes from "./SchoolPages.module.css";

type SchoolPagesProps = {
  navItems: any[];
  setNavItems: Dispatch<SetStateAction<any[]>>;
  heroImage: string;
  paragraph: string;
  header: string;
  subHeading: string;
  coursesRef?: any;
};

const SchoolPages = ({
  navItems,
  setNavItems,
  heroImage,
  paragraph,
  subHeading,
  header,
  coursesRef,
}: SchoolPagesProps) => {
  // Utils

  return (
    <Layout>
      <div className={classes.container}>
        <SchoolPagesHeroSection
          heroImage={heroImage}
          paragraph={paragraph}
          header={header}
          subHeading={subHeading}
          coursesRef={coursesRef}
        />
        <div className={classes.navSection}>
          <SectionsNav navItems={navItems} setNavItems={setNavItems} />
        </div>

        <SchoolPageOverView coursesRef={coursesRef} />
      </div>
    </Layout>
  );
};

export default SchoolPages;
