import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./WhyIseWhatWeOffer.module.css";
import whatWeOffer1 from "../../Assets/Images/whatWeOffer1.svg";
import whatWeOffer2 from "../../Assets/Images/whatWeOffer2.svg";
import whatWeOffer3 from "../../Assets/Images/whatWeOffer3.svg";
import Button from "../../Components/Button/Button";

const WhyIseWhatWeOffer = () => {
  const whatWeOffer = [
    {
      image: whatWeOffer1,
      header: "Flexible classes ",
      description:
        "Upgrade your skills with our virtual classes and events, offering convenient online learning.",
    },
    {
      image: whatWeOffer2,
      header: "Real-life projects",
      description:
        "Engage in hands-on tasks and learn through real-life projects for practical, experiential knowledge.",
    },

    {
      image: whatWeOffer3,
      header: "Interactive learning",
      description:
        "Experience personalized tutoring, earn badges, and engage in lively discussions for an immersive learning journey.",
    },
  ];
  return (
    <section className={classes.container}>
      <SectionsHeader
        paragraph="WHAT WE OFFER YOU"
        header="Why iṣẹ School"
        style={{ textAlign: "center" }}
      />

      <div className={classes.whatWeOfferSection}>
        {whatWeOffer.map((data, i) => {
          return (
            <div key={i} className={classes.whatWeOffer}>
              <img src={data.image} alt={data.header} />
              <p>{data.header}</p>
              <p>{data.description}</p>
            </div>
          );
        })}
      </div>

      <div className={classes.buttonSection}>
        <Button
          type="primary"
          onClick={() => {
            window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-up`);
          }}
        >
          {" "}
          Get Started
        </Button>
      </div>
    </section>
  );
};

export default WhyIseWhatWeOffer;
