import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import breadcrumbsBack from "../../Assets/Images/breadcrumbsBack.svg";
import classes from "./CourseDetailBreadcrumbs.module.css";
import { useContext } from "react";
import { SchoolsContext } from "../../Context/SchoolsContext";
import { useParams } from "react-router-dom";

const CourseDetailBreadcrumbs = () => {
  // Context
  const { iseCourseDetail } = useContext(SchoolsContext);

  // Router
  const { schoolSlug, schoolId } = useParams();

  // Utils
  const breadCrumbsArrayProps = {
    image: breadcrumbsBack,
    array: [
      {
        title: "Explore courses",
        route: `/${schoolSlug}/${schoolId}`,
      },
      {
        title: iseCourseDetail?.data?.name,
        route: `/${schoolSlug}/${schoolId}/${iseCourseDetail?.data?.id}`,
      },
    ],
  };
  return (
    <div className={classes.container}>
      <Breadcrumbs {...breadCrumbsArrayProps} />
    </div>
  );
};

export default CourseDetailBreadcrumbs;
