import React, { LegacyRef, useContext } from "react";
import CourseCard from "../../Components/CourseCard/CourseCard";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./SchoolPageCourses.module.css";
import subClasses from "../HomePageIseCourses/HomePageIseCourses.module.css";
import { SchoolsContext } from "../../Context/SchoolsContext";
import { useParams } from "react-router-dom";

type SchoolPageCoursesProps = {
  coursesRef: LegacyRef<HTMLDivElement>;
};

const SchoolPageCourses = ({ coursesRef }: SchoolPageCoursesProps) => {
  // Context
  const { schoolsDetails } = useContext(SchoolsContext);

  // Router
  const { schoolSlug, schoolId } = useParams();

  // Utils
  const schoolData = schoolsDetails.data;
  return (
    <div className={classes.container} ref={coursesRef}>
      <SectionsHeader
        paragraph="WHAT WE OFFER YOU"
        header="Courses in this school"
      />
      <div className={subClasses.coursesSection}>
        {schoolData?.courses?.map((data: any, i: number) => {
          return (
            <div key={data.id} className={subClasses.subClasses}>
              <CourseCard
                title={data.name}
                image={data.cover_image}
                description={data.description}
                duration="4 months"
                paid={true}
                route={`/${schoolSlug}/${schoolId}/${data.id}`}
                buttonText="Explore course"
              />{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SchoolPageCourses;
