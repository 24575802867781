import classes from "./FaqLayout.module.css";
import SectionsHeader from "../SectionsHeader/SectionsHeader";
import Layout from "../Layout/Layout";
import { useState } from "react";
import AboutUsFaq from "../../Containers/AboutUsFaq/AboutUsFaq";
import SchoolsFaq from "../../Containers/SchoolsFaq/SchoolsFaq";
import TutionFaq from "../../Containers/TiutionFaq/TiutionFaq";
import CoursesFaq from "../../Containers/CoursesFaq/CoursesFaq";
import HomePageAreYouConvinced from "../../Containers/HomePageAreYouConvinced/HomePageAreYouConvinced";
import SectionsNavCenter from "../SectionsNavCenter/SectionsNavCenter";

const FaqLayout = () => {
  // State
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: "About us",
      isActive: true,
      activeComponent: <AboutUsFaq />,
    },
    {
      title: "Schools",
      isActive: false,
      activeComponent: <SchoolsFaq />,
    },
    {
      title: "Tuition",
      isActive: false,
      activeComponent: <TutionFaq />,
    },
    {
      title: "Courses",
      isActive: false,
      activeComponent: <CoursesFaq />,
    },
  ]);

  //   Utils
  const activeComponent = navItems.find((data) => {
    return data.isActive;
  }).activeComponent;

  return (
    <Layout>
      <section className={classes.container}>
        <div className={classes.header}>
          <SectionsHeader
            paragraph="LOOKING FOR ANSWERS?"
            header="We’ve got you covered"
            description="Explore our Frequently Asked Questions. Find clarity and guidance about us, tuition, enrollment, courses, and more."
            headerIsBlack
            style={{ textAlign: "center" }}
          />
        </div>

        <div className={classes.sectionNav}>
          <SectionsNavCenter
            navItems={navItems}
            setNavItems={setNavItems}
            // containerStyle={{ alignItems: "center", justifyContent: "center" }}
          />
        </div>

        <div className={classes.faqs}>{activeComponent}</div>

        <div>
          <HomePageAreYouConvinced />
        </div>
      </section>
    </Layout>
  );
};

export default FaqLayout;
