import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import Loader from "../Components/Loader/Loader";
import SectionsNav from "../Components/SectionsNav/SectionsNav";
import CourseDetailBreadcrumbs from "../Containers/CourseDetailBreadcrumbs/CourseDetailBreadcrumbs";
import CourseDetailgetStarted from "../Containers/CourseDetailgetStarted/CourseDetailgetStarted";
import CourseDetailGetToKnow from "../Containers/CourseDetailGetToKnow/CourseDetailGetToKnow";
import CourseDetailHeroSection from "../Containers/CourseDetailHeroSection/CourseDetailHeroSection";
import CourseDetailKickStart from "../Containers/CourseDetailKickStart/CourseDetailKickStart";
import CourseDetailLEarningRequirements from "../Containers/CourseDetailLEarningRequirements/CourseDetailLEarningRequirements";
import CourseDetailWhatYoullLearn from "../Containers/CourseDetailWhatYoullLearn/CourseDetailWhatYoullLearn";
import CourseDetailWhatYouNeed from "../Containers/CourseDetailWhatYouNeed/CourseDetailWhatYouNeed";
import CourseDetailWhenWeStart from "../Containers/CourseDetailWhenWeStart/CourseDetailWhenWeStart";
import { AppContext } from "../Context/AppContext";
import { SchoolsContext } from "../Context/SchoolsContext";
import classes from "../Containers/CourseDetailBreadcrumbs/CourseDetailBreadcrumbs.module.css";
import { scrollToElementWithGap } from "../HelperFunctions/scrollToTop";

const CourseDetail = () => {
  // COntext
  const {
    iseCourseDetail,
    getCoursesById,
    getCourseTopics,
    getCourseModulesObject,
  } = useContext(SchoolsContext);

  const {
    courseOverview,
    courseCurricullum,
    courseRequirements,
    courseStartDate,
    coursePayment,
    courseFaqs,
  } = useContext(AppContext);

  // Router
  const { courseId } = useParams();

  // States
  const [navItems, setNavItems] = useState([
    {
      title: "Overview",
      isActive: true,
      activeComponent: null,
      onClick: () => {
        courseOverview.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      },
    },
    {
      title: "Curriculum",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        scrollToElementWithGap(courseCurricullum, 50);
      },
    },
    {
      title: "Requirements",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        scrollToElementWithGap(courseRequirements, 50);
      },
    },
    {
      title: "Start date",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        scrollToElementWithGap(courseStartDate, 50);
      },
    },
    {
      title: "Payments",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        scrollToElementWithGap(coursePayment, 50);
      },
    },

    {
      title: "FAQs",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        scrollToElementWithGap(courseFaqs, 50);
      },
    },
  ]);

  // Effects
  useEffect(() => {
    getCoursesById(courseId as string);
    getCourseTopics(courseId as string);

    // eslint-disable-next-line
  }, []);

  if (iseCourseDetail?.isLoading || getCourseModulesObject?.isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  // Local storage
  const iseAccessToken = localStorage.getItem("iseAccessToken");

  const routeToMainApp = () => {
    console.log(iseAccessToken, "Available");
    if (iseAccessToken) {
      window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-in`);
    } else {
      window.open(`${process.env.REACT_APP_LOGIN_ROUTE}/sign-up`);
    }
  };

  return (
    <Layout>
      <CourseDetailBreadcrumbs />

      <CourseDetailHeroSection />
      <div className={classes.navContainer}>
        <SectionsNav navItems={navItems} setNavItems={setNavItems as any} />
      </div>
      <div ref={courseOverview}>
        <CourseDetailGetToKnow />
      </div>
      <div ref={courseCurricullum}>
        {getCourseModulesObject?.data && <CourseDetailWhatYoullLearn />}
      </div>
      <div ref={courseRequirements}>
        <CourseDetailLEarningRequirements />
      </div>
      <div>
        <CourseDetailgetStarted routeToMainApp={routeToMainApp} />
      </div>
      <div ref={courseStartDate}>
        <CourseDetailWhenWeStart routeToMainApp={routeToMainApp} />
      </div>

      <div ref={coursePayment}>
        <CourseDetailWhatYouNeed />
      </div>
      <div ref={courseFaqs}>
        <CourseDetailKickStart routeToMainApp={routeToMainApp} />
      </div>
    </Layout>
  );
};

export default CourseDetail;
